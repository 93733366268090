export const firebaseConfig = {
  apiKey: "AIzaSyATL59RGRcwwqjs-Rah-uGQSu9GTE3SAxQ",
  authDomain: "mainstaymed.firebaseapp.com",
  databaseURL: "https://mainstaymed.firebaseio.com",
  projectId: "mainstaymed",
  storageBucket: "mainstaymed.appspot.com",
  messagingSenderId: "884594283414",
  appId: "1:884594283414:web:7be15013381f044760c3b7"
};

export const vapidKey = `BIC9H-iLVcNF4AyEeo27qQzrNy5m6GcjudTXI5zi2gy-ThzQZQVtIEnml4MS7UbYgw1n6yJhp04IoXC6lwnDyRs`;

// ...

const region = 'us-central1';

export function getCloudFunctionUrl(cfName) {
  if (process.env.REACT_APP_TOKEN_EMULATOR === 'true') {
    return `http://localhost:5001/${firebaseConfig.projectId}/${region}/${cfName}`;
  } 

  return `https://${region}-${firebaseConfig.projectId}.cloudfunctions.net/${cfName}`;
}